.users-page {
    height: calc(100% - 52px);

    // .pagination {
    //     position: absolute;
    //     bottom: -50px;
    //     left: 50%;
    //     transform: translateX(-50%);
    // }
    table {
        margin-bottom: 0;
    }
    tbody {
        tr {
            &:last-child {
                border-bottom: #fff;
            }
        }
    }
    td {
        padding: 16px 12px;
    }
    &__avatar {
        width: 60px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .card {
        margin-top: 20px;
        height: calc(100% - 25px);
        margin-bottom: 0 !important;
    }
    .user__checkbox,
    .user__checkbox-header {
        display: flex;
        input {
            margin: auto;
            cursor: pointer;
        }
    }

    .wrapper__table {
        position: relative;
        height: calc(100vh - 355px);
        overflow: overlay;
    }

    .loading {
        height: calc(100vh - 355px);
    }

    .search-label {
        width: 100%;
    }
    .search {
        display: flex;
        align-items: center;
        span {
            width: 20%;
        }
        label {
            width: 80%;
        }
    }

    .users-table__filter {
        margin-bottom: 20px;
        .field-filter {
            display: flex;
            align-items: center;
            width: 100%;
            span.label {
                width: 20%;
            }
            div.user__form-select {
                width: 80%;
            }
        }
    }
}
