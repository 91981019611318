.issues-list {
    .issue {
        // height: 210px;
        &-title {
            @include cut-text(1);
            max-width: 400px;
        }
        &-location {
            @include cut-text(1);
        }
        &-total,
        &-discount {
            @include cut-text(1);
        }
    }
}

.issue {
    &__payment {
        &-detail {
            margin: 0;
            padding: 0;
        }
    }
    &__detail {
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            margin-bottom: 0;
            overflow-y: overlay;
        }
        &-categories {
            height: 30%;
            overflow-y: overlay;
            margin-bottom: 0;
        }
        &-information {
            overflow-y: hidden;
        }
    }
}

.issues-pagination,
.events-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 64px;
}

.issue-detail {
    &-file {
        span {
            @include cut-text(1);
        }
    }
}
