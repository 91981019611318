.portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.2s linear;
}

.portal-overlay .confirm-dialog {
    z-index: 1000000000000111;
    padding: 16px;
    background-color: white;
    width: 400px;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.portal-overlay .confirm-dialog__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.btn-dialog {
    outline: none;
    padding: 6px 10px;
    border: 1px solid #000;
    margin: 0 10px;
}
