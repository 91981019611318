//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
    height: 100vh;
}

#root,
.app {
    height: 100%;
}

.form-control,
input {
    &::placeholder {
        color: #c4c4c4;
    }
}
