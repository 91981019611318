.skills {
    &-attachments {
        position: relative;
        padding-top: 56.25%;

        video,
        img {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
}
