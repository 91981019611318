@import url('https://cdn.syncfusion.com/ej2/material.css');

.event {
    &-search {
        display: flex;
        align-items: flex-end;
        gap: 0 20px;
        width: 100%;
        max-width: 100%;
    }
    &-filter {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 5px;
        span {
            width: 20%;
        }
    }
    &-actions {
        display: flex;
        align-items: center;
        gap: 0 20px;
    }

    &-date {
        width: 220px;
        max-width: 100%;
        input {
            &:disabled {
                background-color: #f0f0f0;
            }
        }
    }
    &-category {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        &-thumbnail {
            align-items: center;
            color: #fff;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
        }
    }
    &-card {
        min-height: 230px;
        position: relative;

        &__delete {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: transparent;
            display: none;
            text-align: right;
        }

        &:hover {
            .event-card__delete {
                display: block;
            }
        }
    }
    &-content {
        padding: 1.25rem;
        min-height: 1px;
        word-wrap: break-word;
    }
    &-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    &-title {
        display: flex;
        flex-direction: column;
    }
    &-subTitle {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    &-status {
        font-size: 15px;
    }
    &-desc {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        p,
        a,
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            padding: 0;
        }
    }
    &-subDesc {
        margin-top: auto;
        flex: 0;
    }
    &__detail {
        &-categories,
        &-content {
            height: calc(100vh - 270px);
            overflow-y: overlay;
        }

        &-categories {
            height: calc(100vh - 355px);
        }
    }
    &__create {
        &-title {
            text-align: center;
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .page-title {
        flex-direction: column;
        gap: 20px 0;
    }
    .event {
        &-col {
            max-width: 100%;
        }
        &-search {
            width: 100%;
        }
        &-filter {
            justify-content: flex-start;
            .btn {
                display: block;
                width: 100%;
            }
        }
        &-actions {
            width: 90%;
        }
    }
}
