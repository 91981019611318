.workers-page {
    height: calc(100% - 52px);

    .pagination {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
    }
    table {
        margin-bottom: 0;
    }
    tbody {
        tr {
            &:last-child {
                border-bottom: #fff;
            }
        }
    }
    td {
        padding: 16px 12px;
    }
    &__avatar {
        width: 60px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .card {
        margin-top: 20px;
        height: calc(100% - 25px);
        margin-bottom: 0 !important;
    }
    .user__checkbox,
    .user__checkbox-header {
        display: flex;
        input {
            margin: auto;
            cursor: pointer;
        }
    }

    .wrapper__table {
        position: relative;
        height: calc(100vh - 235px);
        overflow: overlay;
    }

    .loading {
        height: calc(100vh - 235px);
    }

    .search-label {
        width: 100%;
    }
    .search {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 10px;
        span {
            width: 20%;
        }
        label {
            width: 80%;
            margin-bottom: 0;
        }
    }

    .workers-table__filter {
        margin-bottom: 20px;
        .field-filter {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 0 10px;
            span.label {
                width: 25%;
                margin-right: 5px;
            }
            div.user__form-select {
                width: 75%;
            }
        }
    }
}
