.row__notification {
    height: inherit;
    padding: 10px 0;

    .col-12 {
        height: inherit;
    }

    .notification {
        &__container {
            display: flex;
            align-items: stretch;
            height: inherit;
            height: 840px;
            background: $white;

            .rich-editor {
                min-height: 600px;
                &:hover {
                    cursor: text;
                }
            }
            &__richTextEditor {
                max-height: 300px;
            }
        }
        &__leftbar {
            background: $white;
            .react-bootstrap-table.table-responsive {
                height: 638px;
            }

            .users-page {
                display: flex;
                flex-direction: column;

                .wrapper__table {
                    position: relative;
                    height: 700px;
                    overflow: overlay;
                }
                .notification__pagination {
                    .pagination {
                        padding-bottom: 0;
                        bottom: 8px;
                    }
                }
                .card-body {
                    padding: 1rem 0 1rem 1rem;
                }
            }

            .pagenotfound {
                height: 700px;
                .me404 {
                    margin-left: 20px;
                    width: 100%;
                }
            }
        }
        &__rightbar {
            background: $white;
            margin-left: 0;
            padding: 10px 20px;
            order: 1;
            border-right: 5px solid #f3f4f7;
            border-left: none;
            height: 100%;

            form {
                height: 100%;
            }
        }
        &__page {
            .pagination {
                padding-bottom: 25px;
            }
            .card {
                box-shadow: none !important;
            }
        }

        &-subDesc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    .search-label {
        width: 97%;
    }
}

.notification {
    &-desc,
    &-subDesc {
        display: -webkit-box;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    &-subDesc {
        -webkit-line-clamp: 2 !important;
    }
    &-actor,
    &-issue {
        font-style: italic;
    }
    &-issue {
        padding: 0;
        margin: 0;
    }
    &-detail {
        &__thumbnail {
            width: 60px;
            height: 60px;
        }
    }
    &__table {
        &-title,
        &-content,
        &-detail {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }
}

.wrapper__editor {
    max-height: 222px;
    height: 100%;
    overflow-y: auto;
}

@media screen and (max-width: 1200px) {
    .col-top {
        margin-bottom: 10px;
    }

    .pagenotfound {
        height: 580px;
    }
}

@media screen and (max-width: 1200px) {
    .col-top {
        margin-bottom: 10px;
    }

    .pagenotfound {
        height: 580px;
    }
}
