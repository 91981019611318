.user-detail {
    .card-body {
        position: relative;
    }
    &__btn-edit {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .badge {
        color: $bg-leftbar-dark;
    }
    &__avatar {
        width: 400px;
        height: 400px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
