.b-form {
    .error {
        color: rgb(248, 51, 51);
    }

    .form-group {
        align-items: center;

        .form-check.form-switch {
            width: 20% !important;
            padding-left: 10px;

            input {
                margin-left: 0;
            }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
