//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px;
        border: none;
    }
}

.pagination {
    margin-bottom: 0 !important;
    padding-top: 2px;
    height: 50px;
}
