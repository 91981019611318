//
// tables.scss
//

//Table centered (Custom)
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

// Custom table components (Custom)
.table {
    th {
        font-weight: $font-weight-bold;
        position: sticky;
        top: -1px;
        z-index: 5;
        background-color: $white;
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    &-wrapper {
        max-height: $table-max-height;
    }
    &-user-wrapper {
        max-height: $table-user-max-height;
    }
    &-skills {
        max-height: $table-skills-max-height;
    }
    &-worker-wrapper {
        max-height: $table-worker-max-height;
    }
    &-event-wrapper {
        max-height: $table-event-max-height;
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

// Tables fluid
.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}
