@import url('https://cdn.syncfusion.com/ej2/material.css');

.event-banner {
    &-card {
        min-height: 230px;
        position: relative;

        &__delete {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: transparent;
            display: none;
            text-align: right;
        }

        &:hover {
            .event-banner-card__delete {
                display: block;
            }
        }
    }
}
