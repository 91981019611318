.event-detail {
    &__thumbnail {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 1024px) {
    .event-detail {
        &__title {
        flex-direction: row;
        }
    }
}
